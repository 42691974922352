
/*body {
    color: #565656;
    font-family:"SourceSansPro-Regular";
    font-size: 12px;
}*/
*{
  margin: 0px;
  padding: 0px;
  font-family: sans-serif;
}
body
{
  font-family: lato !important;
  background-color: #f8f8f8 !important;
  font-size: 12px;
}
.scroll-x
{
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

a{ outline: none; text-decoration: none; }
a:hover, a:focus{ outline: none; text-decoration: none; }

.btn {-moz-user-select: none;
      background-image: none;
      border: 1px solid transparent;cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857;
      margin-bottom: 0;
      padding: 6px 12px;
      text-align: center;vertical-align: middle;
      white-space: nowrap;border-radius: 0px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;}
      
.panel-title > .small, .panel-title > .small > a, .panel-title > a, .panel-title > small, .panel-title > small > a {
    color: inherit;
}
.btn.focus, .btn:focus, .btn:hover {
    color: #dedede;
    text-decoration: none;
}
a {
    color: #17319f;
    text-decoration: none;
}
.panel-body {
    text-align: justify;
}
.nopadding {
    padding: 0 !important;
}
.panel-body {
    padding: 10px;
}
.popup_scroll{height: 500px; overflow: hidden; overflow-y: scroll;}

#notification {
    position: fixed;
    top: 33%;
    width: 100%;
    z-index: 2000!important;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    font-weight: 700;
    padding: 15px;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.alert-success hr {
    border-top-color: #c9e2b3;
}
.image_section{min-height: 300px;}
main{min-height: 250px;}
.btn{ font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; }

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000 !important;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000 !important;
}

.deliver_btn {
    background: #fff !important;
    box-shadow: 0 0 1px rgba(0,0,0,.12), 0 1px 1px rgba(0,0,0,.2);
    color: #17319f;
    margin: 15px 0;
    text-align: center;
    text-transform: uppercase;
    display: inline;
    border: 1px solid #17319f !important;
    padding: 6px 8px;
    width: auto!important;

}
.deliver_btn:hover{ background: #fff !important; color: #17319f;}

.mobile_details_panel .deliver_btn {
    margin: 9px 0px;
}
.about_title{text-align: left; 
  margin:0px; 
  font-size: 16px; 
  color: #7b7a7a; 
  text-decoration: none;
  padding: 12px 15px;
  background-color: #f3f3f3; }


@media screen and (min-width: 320px) {
  .deliver_btn {
    width : 100% !important;
  }
}
h4{font-size: 14px;}
.panel-title{text-transform: capitalize;}